
.project-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
 }
 
 .project-info--name {
    font-family: 'Panton Bold';
    font-size: 28px;
    line-height: 30px;
 }
 
 .project-info--developer div:first-child {
    color: #8B9BC7;
 }

 .project-info--developer div:last-child {
    color: #4B4C63;
 }

.project-name{
    width: 100%
}
.project-name-inner{
    font-size: 18px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
}
.project-priject-extension{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background-color: #fff;
    border-radius: 8px !important;
    font-size: 26px;
    margin: 20px 0;
}
.ant-collapse-expand-icon {
    /* display: none; */
    position: absolute;
    right: 10px;
}
.ant-collapse-item-active .ant-collapse-header {
    color: #fff !important;
    background-color: #876DFF !important;
}

.ant-collapse>.ant-collapse-item{
    border: none;
}
.ant-collapse-content {
    border: none;
}

.ant-collapse-content>.ant-collapse-content-box{
    padding: 0;
}
.project-token{
    font-size: 14px;
    width: 600px;
    margin-left: 0px !important;
}