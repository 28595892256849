.extension-card{
    width: calc(33% - 20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
    margin: 10px;
    min-height: 150px;
    box-sizing: border-box;
    /* filter: drop-shadow(0 4px 4px rgba(0,0,0,.25)); */
    border-radius: 16px;
}
.extension-card-head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 24px 24px 0px 24px;
    box-sizing: border-box;
    font-weight: 600;
}

.extension-card-head-logo{
    width:56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(107, 147, 192, 0.4);
    margin-right: 20px;
    /* justify-content: center; */
}
.extension-card-head-logo img{
    width: 90%;
}

.extension-card-head-titles{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}
.extension-card-head-name{
    width: 100%;
    font-size: 18px;
    font-family: 'Panton Bold';
}
.extension-card-head-name span{
    color: #1A53F0;
    font-family: 'Panton Medium';
}
.extension-card-head-short-description{
    color: #6A4BFF;
    font-family: 'Panton Bold';
}
.extension-card-description{
    width: 100%;
    justify-content: center;
    padding: 10px 24px 0px 24px;
    box-sizing: border-box;
    max-height: 100px;
    min-height: 60px;
    overflow-y: auto;
    font-size: 12px;
    color: #6B758E;
}

.extension-card-options{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px 24px 14px 24px;
    box-sizing: border-box;
}
.extension-card-options-link{
    display: flex;
    align-items: center;
    /* width: 15%; */
}

.extension-card-options-link p{
    margin-right: 5px;
    margin-bottom: 0;
    font-family: 'Panton ExtraBold';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: right;
    text-transform: uppercase;
    color: #6B93C0 !important;
}

.extension-card-options-status{
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    width: 35%;
}
.extension-card-options-status p{
    width: 100%;
    margin: 0;
}