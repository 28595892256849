#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-header {
  background: #fff;
}

.dashboard-logo{
  width: 100%;
  min-height: 64px;
  max-height: 64px;
  /* background-color: var(--main-bg-color); */
  padding: 14px;
  display: flex;
  box-sizing: border-box;
}

.dashboard-logo-img{
  width: 100%;
  color: #fff;
}
.ant-layout-sider{
  background-color: #FFF;
}

.ant-layout-content{
  /* background-image: url('https://status.citadel.one/imgs/main-bg.jpg') !important; */
  background: linear-gradient(255.4deg, #F0F2FC 0%, #EDF2FC 100%);
  box-shadow: inset 7px 7px 40px -24px #6a4bff;
  margin: 0 !important;
  overflow-y: auto !important;
}
.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--main-bg-color);
}
.site-layout .site-layout-background {
  /* background-color: var(--main-bg-color); */
  /* background: linear-gradient(255.4deg, #F0F2FC 0%, #EDF2FC 100%); */
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7c63f5;
  /* height: 55px; */
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>a, .ant-menu-dark .ant-menu-item>span>a {
  color: #000
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .anticon+span, .ant-menu-dark .ant-menu-item-selected>a, .ant-menu-dark .ant-menu-item-selected>a:hover, .ant-menu-dark .ant-menu-item-selected>span>a, .ant-menu-dark .ant-menu-item-selected>span>a:hover{
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .anticon+span, .ant-menu-dark .ant-menu-item-selected>a, .ant-menu-dark .ant-menu-item-selected>a:hover, .ant-menu-dark .ant-menu-item-selected>span>a, .ant-menu-dark .ant-menu-item-selected>span>a:hover .icon{
  filter: invert(99%) sepia(11%) saturate(10%) hue-rotate(164deg) brightness(160%) contrast(150%);
  color: #fff
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .anticon+span, .ant-menu-dark .ant-menu-item-selected>a, .ant-menu-dark .ant-menu-item-selected>a:hover, .ant-menu-dark .ant-menu-item-selected>span>a, .ant-menu-dark .ant-menu-item-selected>span>a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .anticon+span, .ant-menu-dark .ant-menu-item-selected>a, .ant-menu-dark .ant-menu-item-selected>a:hover, .ant-menu-dark .ant-menu-item-selected>span>a, .ant-menu-dark .ant-menu-item-selected>span>a:hover {
  color: #fff;
}

.dev-token {
  width: 100%;
  margin-left: 20px;
  display: flex;
}

.dev-token img {
  margin-left: 18px;
  cursor: pointer;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item, .ant-menu-dark.ant-menu-vertical .ant-menu-item {
  display: flex;
}
.ant-menu-item .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-submenu-title .anticon {
  min-width: 20px;
}