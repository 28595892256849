@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');

@font-face {
font-family: Panton;
src: url(./panton/Panton.otf);
}
@font-face {
font-family: 'Panton Light';
src: url(./panton/Panton\ Light.otf);
}
@font-face {
font-family: 'Panton Bold';
src: url(./panton/Panton\ Bold.otf);
}
@font-face {
font-family: 'Panton ExtraBold';
src: url(./panton/Panton\ ExtraBold.otf);
}
@font-face {
font-family: 'Panton Medium';
src: url(./panton/Panton\ SemiBold.otf);
}
  
@import url('./patternfly/patternfly.css');

;
  
  
:root {
    --main-bg-color: #EEF2FC;
    --main-border-radius: 25px;
    --main-border: 1px solid #C3CEEB;
    --main-box-shadow: 0px 15px 50px rgba(80, 100, 124, 0.1), 0px 10px 15px rgba(80, 100, 124, 0.16);
    --filter-box-shadow: box-shadow: 0px 15px 50px rgba(80, 100, 124, 0.1), 0px 10px 15px rgba(80, 100, 124, 0.16);
    --filter-bg-color: #6A4BFF;
    --success-color: #0FB774;
    --circle-size: 150px;
    --circle-radius: 75px;
    --pf-global--link--Color: #06c;
    --pf-global--link--Color--hover: #004080;
    --pf-global--Color--100: #151515
}

body{
    font-family: 'Panton Medium';
    background-image: url('https://status.citadel.one/imgs/main-bg.jpg');
    background-color: #fff;
    /* background-color: var(--main-bg-color); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
}


.ant-input-affix-wrapper>input.ant-input{
    color: #3C5B7E;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector {
    color:  #3C5B7E !important;

}
.ant-layout {
    height: 100vh;
}
.form-auth {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 150px;
    box-sizing: border-box;
}

.form-auth-inner{
    width: 30%;
    text-align: center;
}
.form-auth-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.form-auth-logo-inner{
    width: 20%;
}

.ant-input{
    padding: 8px;
    border: 1px solid #C6D1FF;
    background-color: #F4F6FF;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    color: #292929;
    font-size: 15px;
    font-weight: 600;
    margin-top: 2px;
    color: #A8C3E1;
    font-size: 15px;
    font-weight: 600;
}

.ant-select-selection-search {
    display: flex;
    align-items: flex-end;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
    color: #A8C3E1;
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A8C3E1;
    opacity: 1; /* Firefox */
  }

.ant-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A8C3E1;
}

.ant-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #A8C3E1;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
    color: #A8C3E1;
}



.ant-input-password{
    background-color: #F4F6FF;
    border: none;
}

.ant-input-affix-wrapper-status-error{
    background: #F4F6FF;
}

.ant-input-affix-wrapper {
    padding: 8px;
    border-radius: 8px;
    background: #F4F6FF;
}

.ant-form-item-label>label{
    font-weight: 600;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: #F4F6FF;
}

.ant-alert {
    padding: 1px 10px;
}

.ant-alert-warning {
    background-color: #fff6ec;
    border: 1px solid #ff900d;
}

.ant-alert-warning .ant-alert-message {
    color: #ff900d;
    font-family: 'Panton Bold';
}

.ant-alert-success {
    background-color: #e1fff7;
    border: 1px solid #0fb774;
}

.ant-alert-success .ant-alert-message {
    color: #0fb774;
}

.ant-collapse-content{
    background-color: transparent;
}

.ant-collapse{
    background-color: transparent;
    border: none
}
.ant-menu-dark .ant-menu-item-active>a, .ant-menu-dark .ant-menu-item-active>span>a, .ant-menu-dark .ant-menu-item:hover>a, .ant-menu-dark .ant-menu-item:hover>span>a, .ant-menu-dark .ant-menu-submenu-active>a, .ant-menu-dark .ant-menu-submenu-active>span>a, .ant-menu-dark .ant-menu-submenu-open>a, .ant-menu-dark .ant-menu-submenu-open>span>a, .ant-menu-dark .ant-menu-submenu-selected>a, .ant-menu-dark .ant-menu-submenu-selected>span>a, .ant-menu-dark .ant-menu-submenu-title:hover>a, .ant-menu-dark .ant-menu-submenu-title:hover>span>a {
    color: #6A4BFF;;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
    margin-bottom: 0;
    margin-top: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    height: 55px;
}

.ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item:not(:last-child) {
    /* margin-bottom: 0; */
}   

.ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    display: flex;
    justify-content: center;
}