.checkbox {
    border: 1px solid #C3CEEB;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-active {
    background-color: #0A2778!important;
    border: none;
}