.main-card {
    background: #FFFFFF;
    border-radius: 16px;
    position: relative;
    height: 240px;
    font-family: 'Panton';
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-left: 20px;
    min-width: 240px;
    filter: drop-shadow(0px 15px 50px rgba(80, 100, 124, 0.1)) drop-shadow(0px 10px 15px rgba(80, 100, 124, 0.16));
}

.card-overlay {
    position: absolute;
    top: 0;
    z-index: -1;
    left: -16px;
    width: 100%;
    height: 100%;

}
.card--title {
    display: flex;
}

.main-card--footer {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    cursor: pointer;
}

.main-card--footer span {
    margin-right: 10px;
    color: #6B93C0;
    font-family: 'Panton ExtraBold';
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    
}

.main-card--logo {
    background: linear-gradient(90deg, #F3E7FF 0%, #CDE6FF 100%);
    border-radius: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    position: relative;
}

.main-card--logo img {
    width: 30px;
    height: 30px;
}

.main-card--content {
    margin-left: 20px;
}

.main-card--name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #000;
}

.main-card--short {
    color: #6A4BFF;
    font-weight: 700;
    font-size: 14px;
}

.main-card--title {
    display: flex;
    align-items: center;
    height: 70px;
}

.main-card--description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B93C0;
    margin: 10px 0;
    max-height: 100px;
}