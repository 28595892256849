.card-select {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px; 
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.card-select--img img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.card-select--name {
    color: #292929;
    font-size: 14px;
    word-break: break-all;
    line-height: 20px;
}

.card-select--icon {
    width: 15px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
}

.card-select--icon svg {
    width: 15px;
    height: 15px;
}