.table-row {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 14px 15px;
    border: 1px solid #F0F3FD;
}

.table-inner {
    margin-left: 40px;
}