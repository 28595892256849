textarea.ant-input::placeholder {
    color: #59779A;
}

textarea.ant-input {
    background: #F4F6FF;
    border: 1px solid #C6D1FF;
    border-radius: 8px;
    color: #3C5B7E;
}

.comment-label {
    color: #170A52;
    letter-spacing: -0.154px;
    font-family: 'Panton Bold';
    font-size: 18px;
    padding-bottom: 5px;
}

