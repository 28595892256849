.empty-container{
    width: 500px;
    height: 400px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 30px;
    justify-content: center;
    box-shadow: -10px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    align-items: center;
}

.empty-image{
    width: 80%;
    display: flex;
    justify-content: center;
}
.empty-image{
    width: 80%;
}
.empty-description{
    width: 80%;
    display: flex;
    justify-content: center;
    font-size: 27px;
    text-align: center;
}