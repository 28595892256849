
.project-container{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}

.project-item{
   width: calc(33% - 20px);
   padding: 16px;
   background-color: #fff;
   border-radius: 8px;
   display: flex;
   justify-content: space-between;
   margin: 10px;
   transition: .3s;
   cursor: pointer;
   position: relative;
   overflow: hidden;
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 35px;
   text-decoration: none;
   color: #000;
}

.project-item-actions{
   width: 30%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}
.project-item-actions div{
   margin-left: 10px;
   border-radius: 4px;
   background-position: center;
}
.delete{
   cursor: pointer;  
   background-image: url('../../../public/img/icons/delete.svg');
   width: 30px;
   height: 30px;
}
.delete:hover{
   background-image: url('../../../public/img/icons/delete_hover.svg');
}
.edit{
   cursor: pointer;  
   background-image: url('../../../public/img/icons/edit.svg');
   width: 30px;
   height: 30px;
}
.edit:hover{
   background-image: url('../../../public/img/icons/edit_hover.svg');
}

.project-item-info-user{
   font-size: 8px !important;

}

.project-item-info-label{
   font-family: 'Panton Light';
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
   /* identical to box height */

   letter-spacing: 0.25px;

   /* Color 12 */

   color: #8B9BC7;
}
.project-item-info-name{
   font-family: 'Panton Bold';
   font-weight: 700;
   font-size: 19px;
   line-height: 23px;
   /* identical to box height */
   
   letter-spacing: 0.25px;
   
   /* Color 2 */
   
   color: #000000;
}

.create-project div {
   border-radius: 50%;
   border: 2px solid #1A53F0;
   width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.create-project{
   background-color: #D7E1FF !important; 
   /* B3B0F5 */
   color: #1A53F0;
   cursor: pointer;
   transition: .3s;
   display: flex;
   justify-content: center;
   align-items: center;
}

.create-project p{
   padding: 0;
   margin: 0;
   margin-left: 15px;
}

.create-project:hover{
   transition: .3s;
   transform: scale(1.03);
}
.project-item:hover{
   transition: .3s;
   background: linear-gradient(90deg, #F3E7FF 0%, #CDE6FF 100%);
}
