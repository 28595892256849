.modal-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.5;
    z-index: 1;
    background-color: #000;
}

.modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 16px;
    border-top: #000;
    min-height: 200px;
    max-height: 100%;
    min-width: 550px;
    z-index: 999;
    padding: 20px 30px;
    max-width: 1500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-header {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Panton Bold';
    margin-bottom: 20px;
    border-bottom: 1px dashed #BCC2D8;
    padding-bottom: 5px;
}

.modal-close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 20px;
}
