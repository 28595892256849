/* .extensions-list{
    width: 100%;
    margin-bottom: 20px;
}

.builds{
    width: 100%;
    display: flex;
    min-height: 100px;
    justify-content: center;
    /* align-items: center;
} 
 */



.validation-container{
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 8px;
}

 .ant-comment-inner{
    padding: 16px;
    margin-bottom: 1px;
 }

 .comment-card--admin > div {
   background-color:rgba(79, 112, 217, 0.2);
 }

 .comment-card--dev > div {
   background-color: rgba(124, 99, 245, 0.2);
 }

 .ant-spin-nested-loading{
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 4px;
 }

 .ant-list-split .ant-list-header{
    border-bottom: none;
 }

 .ant-list{
    /* margin-left: 40px; */
 }
 

 .build-upper {
   display: flex;
   justify-content: space-between;
}

.build-right {
   width: 45%;
   display:  flex;
   flex-direction: column;
   align-items: flex-end;
}

.ant-alert {
   width: 50%;
   text-align: center;
}

.build-date {
   margin-top: 10px;
}

.build-date span {
   color: #6B93C0;
   font-size: 12px;
}

.build-data--wrapper {
   padding: 10px 16px;
}

.build-data--wrapper::after {
   content: "";
   display: block;
   border-bottom: 1px dashed #8B9BC7;
   padding-top: 20px;
}

.build-data {
   word-break: break-all;
}

.build-data span {
   color: #6B93C0;
   margin-right: 8px;
}

.ant-checkbox-inner {
   border: 1px solid #C3D6EC;
   border-radius: 2px;
   margin-right: 10px;
}

.approve{
   width: 30%;
   margin: 0 auto;
}

.build-permissions {
   display: flex;
}



.main-card--logo---delete {
   background-color: #CC3030;
   border-radius: 50%;
   width: 20px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
   cursor: pointer;
   position: absolute;
   right: -15px;
   top: -5px
}