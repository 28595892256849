.extensions{
    width: 100%;
    display: flex;
    /* min-height: 300px; */
    justify-content: center;
    align-items: center;
}
.extensions-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.project-name{
    width: 100%
}
.project-name-inner{
    font-size: 18px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
}
.project-priject-extension{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}