.project-nets {
    display: flex;
    flex-wrap: wrap;
}

.add-network {
    border-radius: 50%;
    background-color: #fff;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.add-network svg {
    width: 15px;
    height: 15px;
}

.network-img img {
    width: 30px;
    height: 30px;
}

.network-name {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-family: 'Panton Medium';
}

.unselect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.unselect span {
    color: #FA3B33;
    border-bottom: 1px dashed #FA3B33;
    line-height: 15px;
}

.unselect-icon {
    border-radius: 50%;
    background-color: #FA3B33;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.unselect-icon svg {
    width: 10px;
    height: 10px;
}

.network-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-all {
    border-bottom: 1px dashed #00A3FF;
    color: #00A3FF;
    line-height: 15px;
    cursor: pointer;
}