.buildCard{
    min-height: 70px;
    background-color: #fff;
    width: calc(33% - 20px);
    border-radius: 16px;
    margin: 10px;
}

.buildCard-head{
    display: flex;
    justify-content: space-between;
}

.buildCard-title{
    font-size: 18px;
    font-family: 'Panton Bold';
    margin: 24px 0px 0px 24px;
}

.buildCard-title span{
    color: #6B93C0;
    font-family: 'Panton Medium';
}
.pf-c-log-viewer__scroll-container {
    height: 600px !important;
}

.buildCard-url{
    margin: 0px 0px 0px 24px;
    display: flex;
    align-items: center;
    color: #6B93C0;
    font-size: 12px;
}
.buildCard-url p{
    margin-left: 5px;
    margin-bottom: 0;
    color: #4B4C63;
    font-size: 12px;
}

.buildCard-url a{
    margin-left: 5px;
    font-size: 12px;
}
.buildCard-url img{
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
}

.buildCard-status{
    margin: 24px 24px 0px 0px;
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}
.buildCard-status p{
    margin-left: 5px;
    margin-bottom: 0;
    color: #6B93C0;
}
.buildCard-status span{
   color: #00A3FF;
}

.buildCard-more{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* padding: 10px 0; */
    /* margin: 0px 24px 24px 0px; */
    align-items: center;
    font-family: 'Panton ExtraBold';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: right;
    text-transform: uppercase;
}
.buildCard-card-options-link {
    display: flex;
    align-items: center;
    width: 20%;
    text-align: flex-end;
    margin: 0px 24px 14px 0px;
}
.buildCard-more p{
    margin-right: 5px;
    margin-bottom: 0;
    color: #6B93C0 !important;
}