.extensions-list{
    width: 100%;
    /* border-radius: 8px; */
    /* overflow: hidden; */
    color: black;
    margin-bottom: 1px;
    background-color: #fff;
}
.extensions-list :hover{
    color: '#fff';
}
.extensions-list :focus{
    color: var(--filter-bg-color);
}
.builds{
    width: 100%;
    display: flex;
    min-height: 100px;
    justify-content: center;
    /* align-items: center; */
}

.extensions-list-item{
    padding: 12px 16px;
}