.common-layout {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    /* overflow: hidden; */
    border-radius: 12px;
    background-color: var(--main-bg-color);
    position: relative;
    height: 100%;
}

.common-layout span {
    /* color: #4B4C63; */
    letter-spacing: -0.154px;
    font-family: 'Panton';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
