
.card-label--content{
    width: 100%;
    font-family: 'Panton Light';
    color: #59779A;
    letter-spacing: -0.154px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.card-label--content img{
    width: 30px;
    height: 30px;
    margin-bottom: 12px;
}
.card-label {
    color: #170A52;
    letter-spacing: -0.154px;
    font-family: 'Panton Bold';
    font-size: 18px;
    border-bottom: 1px dashed #8B9BC7;
    padding-bottom: 10px;
}