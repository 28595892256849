.add-permission {
    border-radius: 50%;
    background-color: #00A3FF;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.project-permission--delete {
    background-color: #CC3030;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.project-permissions {
    max-height: 300px;
    overflow: scroll;
}