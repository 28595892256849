.accordion-icon {
    transform: rotate(0deg);
    transition: transform .3s linear;
    
}

.accordion-icon.active {
    transform: rotate(180deg);
    transition: transform .3s linear;
}


.accordion-icon.dark {
    fill: #1A53F0;
}

.accordion-icon.light {
    fill: #fff;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
}


.accordion-header.cornered {
    border-radius: 8px;
}

.accordion-header.colored {
    background-color: #876DFF;
    padding-bottom: 10px;
   
}

.accordion-header.bottom-border {
    border-bottom: 1px dashed #8B9BC7;
    padding-bottom: 10px;
}

.accordion-title {
    font-family: 'Panton Bold';
    font-size: 18px;
    color: #170A52;
    display: flex;
    align-items: center;
}

.accordion-title.dark {
    color: #1A53F0!important;
}

.accordion-title.light {
    color: #fff;
}

.accordion {
    margin: 5px 0;
    transition: all 1s;
}

.accordion-body {
    margin: 10px 0;
}
