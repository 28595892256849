.button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;  
}

.button-margin {
    margin: 10px 0;
}

.button-start {
    justify-content: start;
}

.button-end {
    justify-content: flex-end;
}

.button-center {
    justify-content: center;
}

.button-wrapper:hover {
    opacity: 0.9;
    transition: 300ms;
}

.button {
    box-shadow: 0px 4px 25px rgba(86, 57, 224, 0.3);
    border-radius: 25px;
    color: #fff;
    padding: 13px 20px;
    text-align: center;
    letter-spacing: -0.154px;
    text-transform: uppercase;
    font-size: 16px;
    outline: none;
    border: none;
    font-family: 'Panton Bold';
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.primary-button {
    background: #7C63F5;
}

.secondary-button {
    background: #4F70CF;
}

.danger-button {
    background: #FA3B33;
}

.default-button {
    background: transparent;
    text-decoration-line: underline;
    color: #0A2778;
    box-shadow: none;
}
