.tooltip-text {
    background-color: #F4F6FF;
    color: #000;
    padding:  15px 20px;
    border-radius: 5px;
    height: 15px;
    position: absolute;
    right: -300px;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    font-family: 'Panton Medium';
}

.tooltip {
    position: relative;
    /* width: 35%; */
}

.tooltip-visible {
    display: flex;
}

.tooltip-hidden {
    display: none;
}
