.table-cell {
    display: flex;
    font-size: 16px;
    font-family: 'Panton Bold';
    color: #6B93C0;
}

.table-cell > * {
    overflow: hidden;
    transition: .2s;
}
.table-cell > *:hover{
    transition: .2s;
    overflow: visible; 
    white-space: normal;
    word-break: break-all;
}