.extension{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content:center;
}




.extension-upper {
    display: flex;
    justify-content: space-between;
}



.extension-upper--right {
    display: flex;
}




.extension-bg-color {
    color: #4F70CF;
    letter-spacing: -0.24px;
    font-weight: 700;
}




