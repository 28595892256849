.abi-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.abi-title {
    font-size: 28px;
    line-height: 30px;
    font-family: 'Panton Bold';
}