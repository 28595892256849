/* .extensions-list{
    width: 100%;
    margin-bottom: 20px;
}

.builds{
    width: 100%;
    display: flex;
    min-height: 100px;
    justify-content: center;
    /* align-items: center;
} 
 */
 .builds-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
 }