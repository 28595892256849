.big-card {
    width: 55%;
    height: 80%;
    overflow-y: auto;
    border-radius: 8px;
    background: #FFFFFF;
    margin-top: 70px;
    justify-content: center;
}

.big-card > * {
    /* height: 40px; */
    width: 100%;
    /* filter: drop-shadow(0 4px 4px rgba(0,0,0,.25)); */
    border-radius: 8px;
    margin-bottom: 1px;
    padding: 16px 16px 0 16px;
}

.big-card--header{
    display: flex;
    align-items: center;
}

.extension-name a{
    font-family: 'Panton Bold';
    font-size: 24px;
}

.extension-name{
    padding-left: 10px;
    padding-bottom: 0px;
}

.extension-logo{
    padding: 10px;
    background: #F0F3FD;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extension-logo img{
    width: 40px;
    height: 40px;
    object-fit:contain;
}

