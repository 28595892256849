.input {
    width: 100%;
    border: 1px solid #C3CEEB;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
}

.input-hint {
    position: absolute;
    top: 5px;
    left: 10px;
    color: #AFBCCB;
}

.input-inner {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.input-prefix {
    margin-right: 5px;
}

.input input {
    outline: none;
    border: none;
    width: 100%;
    color: #6B93C0;
}

.input input::placeholder {
    color: #6B93C0;
}