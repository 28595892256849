.breadcrumb-common {
    display: flex;
    /* width: 22%; */
    position: absolute;
    left: 10px;
    z-index: 100;
    top: 20px;
    height: fit-content;
    align-items: center;
}

.breadcrumb-common > * {
    margin-right: 8px;
}

.breadcrumb-common img {
    width: 10px;
    height: 10px;
}

.breadcrumb-project--name {
    font-family: 'Panton';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
}

.breadcrumb-extension--name {
    font-family: 'Panton';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #4B4C63;
}

.breadcrumb-extension--name span {
    color: #6B93C0;
    font-weight: 400;
    font-size: 18px;
}