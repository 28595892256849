.permission-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.permission-title {
    font-size: 28px;
    line-height: 30px;
    font-family: 'Panton Bold';
}

.permission-actions {
    display: flex;
}

.permission-actions div{
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
}

.permission-parameter {
    color: #6B93C0;
    font-family: 'Panton';
    text-overflow: ellipsis;
}

.permission-name {
    color: #5639E0;
}

