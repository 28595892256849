.add-label{
    text-align: center;
}

.form-group-background{
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    margin: 10px auto;
    width: 70%;
}

.extension-label{
    position: absolute;
    font-family: 'Panton Bold';
    /* width: 100%; */
    font-size: 12px;
    pointer-events: none;
    left: 13px;
    top: 10px;
    color: #4B4C63;
    z-index: 999;
}
.extension-label-before{
    font-family: 'Panton Bold';
    font-size: 12px;
    pointer-events: none;
    color: #4B4C63;
    margin-left: 13px;
}
.extension-label.after-review{ 
    left: 21px;
    color: #3C5B7E;
    font-size: 18px;
    top: 2px;
}
.ant-btn-primary{
    background: #7C63F5;
    border-color: transparent;
    /* Button shadow */
    padding: 22px;
    margin: 10px auto;
    /* box-shadow: 0px 4px 25px rgba(86, 57, 224, 0.3); */
    border-radius: 25px;
    font-family: 'Panton';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    outline: none;
   
    align-items: center;
    text-align: center;
    letter-spacing: -0.154px;
    text-transform: uppercase;
}

.ant-btn-primary span {
    color: #fff;
}

.ant-form {    
    padding: 0px 0px !important;
} 

.form-group-content{
    display: flex;
    justify-content: space-between;
}

.form-group-container{
    display: flex;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    height: 241px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
    background: #F4F6FF;
    border: 1px solid #C6D1FF;
    border-radius: 8px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 60px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    align-items: flex-end;
}
.ant-input-affix-wrapper {
    height: 60px;
    display: flex;
    align-items: flex-end;
    padding-left: 13px;
}

.form-group-label-group{
    position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F4F6FF;
    border-radius: 8px;
}

.ant-form-item {
    margin: 0 0 10px;
}


.ant-form-item-with-help .ant-form-item-explain {
    min-height: 10px;
}

.ant-form-item-explain, .ant-form-item-extra{
    font-size: 12px;
    padding:0;
}

textarea.ant-input{
    height: 100%;
}

.ant-upload.ant-upload-drag{
    border: none;
    border-radius: 8px;
    background-color: #F4F6FF;
}

.chrome-picker{
    background-color: #F4F6FF !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    overflow: hidden !important;
}
.ant-input-prefix, .ant-input-suffix {
    align-self: center;
}

.extension-label-others{
    font-family: 'Panton Bold';
    margin: 10px 0;
    color: #4B4C63;
}

.anticon {
    color: #92B2D6;
}

.project-preview--body {
    background: #FAFBFF;
    border: 1px solid #C6D1FF;
    border-radius: 8px;
    padding: 40px 150px;
}

.project-preview--title {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px 0;
    font-family: 'Panton Bold';
    font-size: 18px;
    line-height: 18px;
    color: #170A52;
}

.project-preview--title span {
    color: #00A3FF;
    border-bottom: 1px dashed #00A3FF;
    cursor: pointer;
}

.app-logo {
    background: linear-gradient(44.13deg, #F7FF00 -29.25%, #FA06A8 137.88%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-logo img {
    width: 25px;
    height: 25px;
}

.app-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}

.app-head div:first-child {
    display: flex;
}

.app-title {
    margin-left: 15px;
}

.app-name {
    color: #6A4BFF;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Panton Bold';
    display: flex;
    align-items: center;
}

.app-name img {
    margin-left: 10px;
}

.app-short {
    color: #6B93C0;
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
}

.app-body--head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px 0;
}

.app-label {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Panton Bold';
    color: #000000;
}

.app-show--more {
    color: #00A3FF;
    font-size: 14px;
    border-bottom: 1px dashed #00A3FF;
    cursor: pointer;
}

.networks {
    display: flex;
    flex-wrap: wrap;
}

.network-item {
    display: flex;
    justify-content: center;
    flex: 0 0 33%;
    margin-top: 10px;
}


.network-name {
    margin-left: 10px;
}

.network-img img {
    width: 20px;
    height: 20px;
}

.app-desc {
    width: 100%;
    color: #6B758E;
    font-size: 14px;
    line-height: 24px;
}

.block-title {
    color: #170A52;
    font-family: 'Panton Bold';
    font-size: 16px;
    line-height: 40px;
}