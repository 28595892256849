.list {
    margin: 10px 0;
}

.list-item {
    background-color: #F4F6FF;
    margin: 10px 0;
    border-radius: 8px;
    padding: 10px;
}

.list-title {
    color: #5639E0;
    font-family: 'Panton Bold';
    font-size: 18px;
}

.list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #8B9BC7;
    padding-bottom: 10px;
}

.list-type {
    font-family: 'Panton Bold';
    font-size: 16px;
}

.list-description {
    padding-top: 10px;
    color: #59779A;
}

.list-right {
    display: flex;
    align-items: center;
}

.list-actions {
    margin-left: 10px;
}
